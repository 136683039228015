import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import BrandCTA from '../../../components/brand-cta'
import { withPrefix } from "gatsby"
import callout from '../../../images/savings_pg_callout.png'
import ExternalLink from '../../../components/external-link'
import ActiveLink from '../../../components/active-link'
import Hero from '../../../components/hero'

const metaTags = {
  description: 'Read about the Support Center and how to begin the reimbursement process.',
  keywords: 'Homepage',
  title: 'Support Center & Getting Started'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>The SUPPRELIN<sup>&reg;</sup> LA Support Center</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <h2>We’ll be with you every step of the way</h2>
        <p>Because healthcare plans, insurance options, copays, and the process of reimbursement can be complex, we’ll be with you every step of the way. </p>
        <p>The SUPPRELIN<sup>&reg;</sup> LA Support Center offers you assistance as your child begins treatment by verifying benefits, confirming copay or payment responsibilities (if any), to ensure your child’s medicine is shipped. </p>
      </Col>
      <Col xs={12} md={6}>
        <img src={callout} alt="Support Center Contact Info" title="Support Center Contact Info" />
      </Col>
    </Row>
    <Row>
      <Col center='xs'>
        <h2>Your Support Center representative will work to help you understand your benefits</h2>
        <ul className='brand-list'>
            <li>Assisting with verification of insurance coverage </li>
              <li>Clarifying deductibles and copay for treatment and procedures </li>
            <li>Providing information to eligible families about the SHARES Program, a financial support program</li>
            <li>Answering general reimbursement questions </li>
            <li>Coordinating the shipment of SUPPRELIN<sup>&reg;</sup> LA directly to the procedure center identified by your child’s healthcare professional </li>
        </ul>
      </Col>
    </Row>
    <Row>
      <Col center='xs'>
        <h2>Getting started with SUPPRELIN<sup>&reg;</sup> LA</h2>
        <p><strong>Submitting the Form</strong><br/>
        Your healthcare provider may submit a Service Request Enrollment Form to
the SUPPRELIN<sup>&reg;</sup> LA Support Center to verify insurance coverage, benefits and
any next steps, such as prior authorization or product access options.
</p>
        <p><strong>Communications</strong> <br/>A staff member from the SUPPRELIN<sup>&reg;</sup> LA Support Center will provide you and your physician with information about your insurance coverage, and other access or assistance details that may assist you in understanding the treatment journey.</p>
        <p><strong>Continued Support</strong><br/>
        About 11 months after researching your insurance coverage, the SUPPRELIN<sup>&reg;</sup> LA Support Center will remind your child’s doctor that it’s time to evaluate your child's central precocious puberty (CPP) treatment plan and will repeat the insurance verification as instructed by the physician.
        </p>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/patients/caregiver-signup'}
          LinkCaption="Order an all-in-one caregiver's kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP"
        >
          Order an all-in-one caregiver's kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href={withPrefix('/pdfs/SP-05697_Appointment_Reminder_Card_R1_Digital.pdf')}
          LinkCaption="Download appointment reminder tool to help manage your child’s appointments - support center"
        >
              Download appointment reminder tool to help manage your child’s appointments

        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
